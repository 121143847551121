
import React, { useState, useEffect } from 'react';

import './App.css';
import "./components/fonts/ClashDisplay-Semibold.ttf";
// import "./components/fonts/ClashDisplay-Bold.ttf";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import LearnPage2 from './components/pages/learnPage2';
import LearnPage1 from './components/pages/learnPage1';
import FanTokens from './components/pages/fantokensPage';
import CompanyPage from './components/pages/companyPage'; 
// import { Home } from './components/pages/Home'; 
// import { Parallax } from './components/pages/ParallaxPage';
import SupportPage from './components/pages/supportPage';
import RoadmapPage from './components/pages/roadmapPage';
import CareerPage from "./components/pages/careerPage";
import HomePage from './components/pages/homePage';
import WithdrawMoney from './components/pages/withdrawMoney';

import { Provider } from 'react-redux';
import store from './components/pages/redux/store'








function App() {
 
  // const { pathname } = useLocation();




  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [pathname]);


 


 



  return (
   <Provider store={store}>
     <div className="App">
       <>
      
       
   <BrowserRouter>

     <Routes>

       <Route path='/' element={<HomePage/>}/>
       <Route path='/learnPage1' element={<LearnPage1/>}/>
       <Route path='/learnPage2' element={<LearnPage2/>}/>
       <Route path='/fantokensPage' element={<FanTokens/>}/>
       <Route path='/companyPage' element={<CompanyPage/>}/>
       <Route path='/supportPage' element={<SupportPage/>}/>
       <Route path='/roadmapPage' element={<RoadmapPage/>}/>
       <Route path='/careerPage' element={<CareerPage/>}/>
       <Route path='/withdrawMoney' element={<WithdrawMoney/>}/>

     </Routes>
   
   </BrowserRouter>
      
    </>
    </div>
   </Provider>
  );
}

export default App;
