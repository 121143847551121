// import React from "react";
import React, { useEffect, useRef, useState } from "react";

import Partner from "./Partner";
import "../stylesheet/homestyle.css";
import "../stylesheet/trade.css";
import "../stylesheet/header.css";
import "../stylesheet/common.css";

import "../stylesheet/downloadpopup.css";

import Header from "./header";

import CommunitySection from "./gallery/communitySection";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import buybg from "../background-images/buysell-bg-1.png";

import screen3 from "../background-images/screen-3-1.png";
import screen4 from "../background-images/screen-4-1.png";
// import video from "../background-images/video-bg.png";
// import screen1 from "../background-images/screen11.svg";
// import screen2 from "../background-images/screen22.svg";
// import screen3 from "../background-images/screen33.svg";
// import screen4 from "../background-images/screen44.svg";
import arrow from "../icons/arrow-right.png";
import how2 from "../icons/how-2.png";
import how3 from "../icons/how-3.png";
import how4 from "../icons/how-4.png";
import fullyicon from "../icons/fully-icon.png";
import fantocken1 from "../icons/fan-tocken-1.png";
import fantocken2 from "../icons/fan-tocken-2.png";
import fantocken3 from "../icons/fan-tocken-3.png";
import apple from "../icons/apple.png";
import android from "../icons/android.png";

import darrow from "../icons/download-arrow.png";

// partner;
// import iphone1 from '../background-images/background-mobile.svg';
// import iphone2 from '../icons/iphone22.svg';
import iphone1 from "../background-images/background-mobile.png";

import Footer from "./footer";
import Mediapartner from "./mediaPartner";
import { Faq } from "./Faq";
import TeamsSlide from "./teamsSlide";

// trade teams
import trade_btn from "../background-images/trade-button.png";
import graph from "../icons/delhi-g.png";
import srh from "../icons/teams-logo/img3.png";
import delhi from "../icons/teams-logo/img1.png";
import kkr from "../icons/teams-logo/img2.png";
import rr from "../icons/teams-logo/img8.png";

import csk from "../icons/teams-logo/img4.png";
import mumbai from "../icons/teams-logo/img9.png";
import gujarat from "../icons/teams-logo/img6.png";
import rcb from "../icons/teams-logo/img5.png";
import punjab from "../icons/teams-logo/img10.png";
import lucknow from "../icons/teams-logo/img7.png";

import scrollvideo from '../background-images/video/road map final 01.mp4'

// trade teams end

 


import { render } from "react-dom";
// import { VideoScroll } from 'react-video-scroll'

import roadmapfinal from "../background-images/roadmap.mp4";

export const HomePage = () => {



  // const setHeightRef = useRef(null);
  // const vidRef = useRef(null);

  // useEffect(() => {
  //   const vid = vidRef.current;
  //   const setHeight = setHeightRef.current;

  //   const playbackConst = 1000;

  //   const handleLoadedMetadata = () => {
  //     setHeight.style.height = Math.floor(vid.duration)  * playbackConst + "px";
  //   };

  //   const handleScrollPlay = () => {
  //     const frameNumber = window.pageYOffset / playbackConst;
  //     // console.log("pageoffset", frameNumber)
  //     vid.currentTime = frameNumber;
  //     window.requestAnimationFrame(handleScrollPlay);
  //   };

  //   vid.addEventListener("loadedmetadata", handleLoadedMetadata);
  //   window.requestAnimationFrame(handleScrollPlay);

  //   return () => {
  //     vid.removeEventListener("loadedmetadata", handleLoadedMetadata);
  //     window.cancelAnimationFrame(handleScrollPlay);
  //   };
  // }, []);

  const [downpop, setDownpop] = useState(false);

  const downpopfun = () => {
    setDownpop(true);
    document.body.style.overflow = "hidden";
  };

  const downpopfunclose = () => {
    setDownpop(false);

    document.body.style.overflow = "auto";
  };

  const [download, setdownload] = useState(false);

  const changedownload = () => {
    if (window.scrollY >= 700) {
      setdownload(true);
    } else {
      setdownload(false);
    }
  };
  window.addEventListener("scroll", changedownload);

  // ---------------------------trade teams
  const [tradepop, settradepop] = useState(false);

  const trade_pop = () => {
    settradepop(true);
    document.body.style.overflow = "hidden";

    console.log("true popppppppppppppppppppppppppp");
  };
  const trade_close = () => {
    settradepop(false);
    document.body.style.overflow = "auto";
    console.log("falseeeeeeeeeeeeeeeeeeeeeeeeeeeeeee");
  };

  const trade_teams = [
    {
      team_img: srh,
      team_head: "HSVC",
      team_name: "Sunrisers Hyderabad",
      team_coin: "Hyderabad Sportsverse Coins",
      team_redirect:
        "https://www.dextools.io/app/en/polygon/pair-explorer/0x065f0f3316f5c701dbf4ffe6f17a8108f9d6d60e",
    },
    {
      team_img: kkr,
      team_head: "KSVC",
      team_name: "Kolkata Knight Riders",
      team_coin: "Kolkata Sportsverse Coins",
      team_redirect:
        "https://www.dextools.io/app/en/polygon/pair-explorer/0x30d7d423d3474073b264e605262068c064daf0b6",
    },
    {
      team_img: rr,
      team_head: "RSVC",
      team_name: "Rajasthan Royals",
      team_coin: "Rajasthan Sportsverse Coins",
      team_redirect:
        "https://www.dextools.io/app/en/polygon/pair-explorer/0xd1df090a9b09a6b0d3e70e1113f82c028d0b20db",
    },
    {
      team_img: csk,
      team_head: "CSVC",
      team_name: "Chennai Super Kings",
      team_coin: "Chennai Sportsverse Coins",
      team_redirect:
        "https://www.dextools.io/app/en/polygon/pair-explorer/0x999098c9d2e0813c51770e29fc34be7b363fb527",
    },
    {
      team_img: mumbai,
      team_head: "MSVC",
      team_name: "Mumbai Indians",
      team_coin: "Mumbai Sportsverse Coins",
      team_redirect:
        "https://www.dextools.io/app/en/polygon/pair-explorer/0xfd23e45b765a6c7501002373b2196164bf638803",
    },
    {
      team_img: gujarat,
      team_head: "GSVC",
      team_name: "Gujarat Titans",
      team_coin: "Gujarat Sportsverse Coins",
      team_redirect:
        "https://www.dextools.io/app/en/polygon/pair-explorer/0x6f1801b3016c7b0e444246247a3de6018a281f51",
    },
    {
      team_img: rcb,
      team_head: "BSVC",
      team_name: "Royal Challengers Bangalore",
      team_coin: "Bangalore Sportsverse Coins",
      team_redirect:
        "https://www.dextools.io/app/en/polygon/pair-explorer/0x5d5456822ad89231bc8ba3aaa51639406fcc0a8a",
    },
    {
      team_img: punjab,
      team_head: "PSVC",
      team_name: "Punjab Kings",
      team_coin: "Punjab Sportsverse Coins",
      team_redirect:
        "https://www.dextools.io/app/en/polygon/pair-explorer/0x3fecc2fe938f24ca63653814530d9ce0a3b146f6",
    },
    {
      team_img: delhi,
      team_head: "DSVC",
      team_name: "Delhi Capitals",
      team_coin: "Delhi Sportsverse Coins",
      team_redirect:
        "https://www.dextools.io/app/en/polygon/pair-explorer/0xf5b66f7aabe8b36168a36f700573d490b1f20797",
    },
    {
      team_img: lucknow,
      team_head: "LSVC",
      team_name: "Lucknow Super Giants",
      team_coin: "Lucknow Sportsverse Coins",
      team_redirect:
        "https://www.dextools.io/app/en/polygon/pair-explorer/0xfb68c17e16123db9054bfc9700b25a992f41093b",
    },
  ];

  // --------------------------------trade teamd end

  return (
    <div>
      <div className={downpop ? "d-block" : "d-none"}>
        <div className="download-popup">
          <div className="download-popup-child">
            <div className="download-pop" onClick={downpopfunclose}>
              X
            </div>
            <h1>
              Mobile App Coming Soon. Sign up and Join our Community to get
              Whitelisted{" "}
            </h1>
          </div>
        </div>
      </div>

      <div className="home-header">
        <Header hdrop={"white"} hdropa={"black"} scrollColor={"#232234"} />
      </div>

      {/*---------------------------------------------- trade section   */}

      <div className={tradepop ? "d-block" : "d-none"}>
        {/* <div className="trade-globel" onClick={trade_close}></div> */}
        <div className="trade-popup-section">
          <div className="trade-parent">
            <div className="trade-close" onClick={trade_close}>
              X
            </div>

            <h1 className="select-token-heading">
              Select the token to trade on{" "}
              <span style={{ color: "#ea9528" }}>dextools</span>
            </h1>

            <div className="">
              {trade_teams.map((tradedata) => (
                <a
                  href={tradedata.team_redirect}
                  target="_blank"
                  className="trade-child"
                >
                  <img
                    src={tradedata.team_img}
                    style={{ width: "40px", transform: "scale(2.6)" }}
                    alt=""
                  />
                  <div className="trade-innder-text">
                    <h1 className="trade-team-heading">
                      {tradedata.team_head}
                    </h1>
                    <h1 className="trade-team-para">{tradedata.team_coin}</h1>
                  </div>

                  <img
                    src={trade_btn}
                    style={{ width: "12px", height: "16px" }}
                    alt=""
                  />
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/*---------------------------------------------- trade end  */}

      <div className="home-back">
        <div className="background-lines">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 pt-5 largest-platform">
                <h1 className="indias-heading-text pt-5">
                  India’s <span className="hash-logo">#</span>
                  <span className="span-heading-text">1</span>st
                </h1>
                <h1 className="indias-heading-text">
                  <span>& </span>
                  <span className="span-heading-text">Largest </span>
                  Sports Trading Platform{" "}
                </h1>{" "}
                {/* <h1 className="indias-heading-text"> Platform</h1> */}
                {/* button */}
                <p className="watch">
                  Analyze | Trade | Earn <br></br>Make a smart trade on your
                  favourite Team
                </p>
                <div className="india-best-button">
                  <a href="https://qr.page/g/5j4P7cPInUZ" target="_blank">
                    <button className="best-btn">
                      <h3>download app</h3>
                      {/* <img className="img1" src={apple} /> */}
                      <img className="img1" src={android} />
                    </button>
                  </a>

                  <button className="button2" onClick={trade_pop}>
                    <h4>Trade Now</h4>
                  </button>

                  {/* 
                <div className=" text-center home-whitepaper">
                  <a href="https://metalok-testbucket.s3.ap-south-1.amazonaws.com/Whitepaper+Version+2_compressed.pdf" target="_black"><button>download The White Paper</button></a>
                </div> */}
                </div>
                <br></br>
              </div>
              <div className="col-lg-6 pb-8 d-flex align-items-center">
                <img
                  src="https://metalok-testbucket.s3.ap-south-1.amazonaws.com/sportsverse/buysell-bg-1.png"
                  alt="image"
                  className="buy-bg"
                />
              </div>
            </div>
          </div>

          {/* <TeamsSlide /> */}
        </div>

        {/* this ipl we're stronger section start here  */}
        <section className="this-ipl-section ">
          <div className="container">
            <div className="row hitting-section">
              <div className="col-lg-1"></div>
              <div className="col-lg-3 hitting">
                <p>
                  {" "}
                  Hitting it off<br></br> with the IPL Season{" "}
                </p>
              </div>
              <div className="col-lg-8 this-ipl-para1">
                <p>
                  This IPL we’re stronger with Sportsverse. Enjoy the game of
                  cricket and take a chance to gain while experiencing the
                  edge-of-the-seat matches between the champions of the game
                </p>
              </div>
            </div>
          </div>
          {/* <div className="video-bg" >
                        <img src={video} alt="image" className="this-ipl" />
                    </div> */}

          <div className="video-bg">
            <video
              // style={{ maxWidth: "100%", height: "80vh" }}
              //   style={{ maxWidth: "100%",height:"70vh" }}
              autoPlay
              playsInline
              loop
              muted
              controls
              alt="All the devices"
              src="https://metalok-testbucket.s3.ap-south-1.amazonaws.com/ipl+video.mp4"
              className="this-ipl-video"
            />
          </div>
        </section>

        <div className={download ? "d-block" : "d-none"}>
          <div className="download-arrow-parent">
            <h1>download now!</h1>
            <a href="https://qr.page/g/5j4P7cPInUZ" target="_blank">
              <div>
                <img src={darrow} alt="" />
              </div>
            </a>
          </div>
        </div>

        {/* here's how sports exchange section start here  */}
        {/* <div className="empty"></div> */}

        <section className="heres-section">
          <div className="container">
            <div className="row">
              <div className="col-xl-4">
                <div>
                  <p className="heres-how-heading1">
                    Here’s how{" "}
                    <span className="heres-how-sports-span-text"> Sports </span>
                    <span className="exchange-text"> exchange </span>does all
                    the magic{" "}
                  </p>
                  <p className="text-p">
                    {/* Sports tokens will be released on participating
                    teams with a base price, on a secured polygon network. The
                    tokens can be traded on the Sportsverse platform. */}
                    Sports tokens will be released on participating teams with a
                    base price, on a secured polygon network. The tokens can be
                    traded on the Sportsverse platform in accordance with the
                    team’s performance based on the owner’s judgement
                  </p>
                </div>
              </div>
              <div className="col-xl-1"></div>
              <div className="col-xl-7">
                <div className="row px-3">
                  <div className="col-md-6 fan1">
                    <div className="fan">
                      <img src={fantocken1} alt="fan-tocken" />
                      <h1>Release of Fan Tokens</h1>
                      <p>
                        {/* On a secured blockchain network, fan tokens of all participating teams will be released open to buying and selling */}
                        Release of Fan Tokens of all participating teams open to
                        trading
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6 fan2">
                    <div className="fan">
                      <img src={fantocken2} alt="fan-tocken" />
                      <h1>Analyze, Learn and Chance a win</h1>
                      <p>
                        {/* Invest in your favourite team with the highest
                        probability of winning to trade their token. */}
                        {/* Enjoy the play of your favourite teams and anticipate winning along with them. Invest in your favourite team or the team with the highest probability of winning to trade their token on a secured blockchain network and profit from the right call at the right time */}
                        Trade tokens based on the team news & performance
                        analysis
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row px-3">
                  <div className="col-md-6 fan3">
                    <div className="fan">
                      <img src={fantocken3} alt="fan-tocken" />
                      <h1>Buy/sell fan tokens and make money</h1>
                      <p>
                        {/* Trade the tokens on a secured network, buy and sell tokens based on the teams performance and experience the exhilaration of the game along with the players */}
                        Make money by doing a good trade of fan tokens
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* section of how it works mobile */}
        <section className="left-lines">
          <div className="container">
            <h1 className="how-text-heading">How it works?</h1>
            <div className="row  scren-imgsection">
              <div className="col-md-2"></div>
              <div className="col-md-4 m-auto apply-now-section">
                <img
                  src="https://metalok-testbucket.s3.ap-south-1.amazonaws.com/sportsverse/app_gplay_img.png"
                  alt=""
                  className="screen mobile-small-responsive"
                />
                {/* <h1 className="apply-now">Coming Soon</h1> */}
              </div>
              <div className="col-md-1 screen-line"></div>
              <div className="col-md-5 screen-right-div ">
                <div className="mob-res">
                  <img src={arrow} alt="icon" className="how-img" />
                  <p className="install-series">
                    01 <br></br>
                    <br></br>
                    <span className="install-text">Install App</span>
                  </p>
                </div>
                <p className="how-text">
                  {/* Download and Install the Sportsverse application from Google store and App Store or from our website link. Create an account to start trading on the application */}
                  Download and Install the Sportsverse application from Google
                  store and App Store or from our website link. Login or sign-up
                  with us to start trading on the application
                </p>
              </div>
            </div>
            <div className="row pt-5 scren-imgsection">
              <div className="col-md-2"></div>
              <div className="col-md-4 m-auto">
                <img
                  src="https://metalok-testbucket.s3.ap-south-1.amazonaws.com/sportsverse/app_signin_img.png"
                  alt=""
                  className="screen mobile-small-responsive"
                />
              </div>
              <div className="col-md-1 screen-line"></div>
              <div className="col-md-5 screen-right-div">
                <div className="d-flex mob-res">
                  <img src={how2} alt="icon" className="how-img" />
                  <p className="install-series">
                    02 <br></br>
                    <br></br>
                    <span className="install-text">
                      Login using your social media handles
                    </span>
                  </p>
                </div>
                <p className="how-text ">
                  {/* login with your social handle to create a self costodian wallet */}
                  Login or sign-up using your social media handles to
                  automatically create a digital wallet on our secured platform
                </p>
              </div>
            </div>
            <div className="row pt-5 scren-imgsection">
              {/* <div className="col-md-2"></div> */}
              <div className="col-md-6 m-auto">
                <img
                  src={screen3}
                  alt=""
                  className="screen mobile-small-responsive3"
                />
              </div>
              <div className="col-md-1 screen-line" ></div>
              <div className="col-md-5 screen-right-div">
                <div className="d-flex mob-res" >
                  <img src={how3} alt="icon" className="how-img"/>
                  <p className="install-series">
                    03 <br></br>
                    <br></br>
                    <span className="install-text">
                      Watch & Analyze and Trade
                    </span>
                  </p>
                </div>
                <p className="how-text ">
                  Enjoy the match and take the chance of profiting along with
                  experiencing the high of the game. Select the team token you
                  want to invest in, watch and follow the match while trading
                  the tokens on the platform
                </p>
              </div>
            </div>
            <div className="row pt-5 scren-imgsection">
              <div className="col-md-2"></div>
              <div className="col-md-4 m-auto">
                <img
                  src={screen4}
                  alt=""
                  className="screen mobile-small-responsive"
                />
              </div>
              <div className="col-md-1 screen-line"></div>
              <div className="col-md-5 screen-right-div">
                <div className="d-flex mob-res ">
                  <img src={how4} alt="icon" className="how-img build-img" />
                  <p className="install-series">
                    04<br></br>
                    <br></br>
                    <span className="install-text">
                      Build your portfolio, Trade Smart and Earn
                    </span>
                  </p>
                </div>
                <p className="how-text ">
                  Join the community, build your portfolio, make smart
                  judgements and get return of your investments by trading the
                  tokens
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* fast and secure section start here */}
        <section className="pb-5">
          <h1 className="fast-secure-heading"> Fast & Secure Application</h1>

          <p className="fast-secure-text" style={{ marginBottom: "0px" }}>
            Enabled by technology, our sportsverse app prioritises
            speed and security of all operation on board
          </p>

          <div className="container fast">
            <div className="row d-flex justify-content-around pt-5">
              <div className="col-md-2 fully-div">
                <div className="fully1">
                  <img src={fullyicon} alt="" />
                  <h3 className="fully-text-heading">Immersive Experience</h3>
                  <p>
                    With Sportsverse, users won’t miss out the live match
                    experience to trade
                  </p>
                </div>
              </div>
              <div className="col-md-2  fully-div">
                <div className="fully1">
                  <img src={fullyicon} alt="" />
                  <h3 className="fully-text-heading">Community Driven</h3>
                  <p>
                    Build a fandom of your favourite sport and participate
                    together
                  </p>
                </div>
              </div>
              <div className="col-md-2 fully-div">
                <div className="fully1">
                  <img src={fullyicon} alt="" />
                  <h3 className="fully-text-heading">Insight and Analytics</h3>
                  <p>Garner analytics & insights of both the game and trade</p>
                </div>
              </div>
              <div className="col-md-1 d-md-none d-block"> </div>
            </div>
            <div className="row d-flex justify-content-around pt-md-5 pt-0">
              <div className="col-md-1 d-md-none d-block"> </div>
              <div className="col-md-2 fully-div">
                <div className="fully1">
                  <img src={fullyicon} alt="" />
                  <h3 className="fully-text-heading">Fan Rewards</h3>
                  <p>
                    Chance to win Signed merchandise & player meet-greet as
                    exclusive fan rewards
                  </p>
                </div>
              </div>
              <div className="col-md-2 fully-div">
                <div className="fully1">
                  <img src={fullyicon} alt="" />
                  <h3 className="fully-text-heading">
                    Technology-enabled Sports Experience{" "}
                  </h3>
                  <p>
                    Enhance your Sports experience with Next-Gen Technologies
                    like Blockchain & AR
                  </p>
                </div>
              </div>
              <div className="col-md-2 fully-div">
                <div className="fully1">
                  <img src={fullyicon} alt="" />
                  <h3 className="fully-text-heading">
                    Passive Economy to Sports fans
                  </h3>
                  <p>Generate passive income from your favourite sports</p>
                </div>
              </div>
              <div className="col-md-1 d-md-none d-block"> </div>
            </div>
            <div className="row d-flex justify-content-around pt-md-5 pt-0 row-fast-secure-blocks">
              <div className="col-md-1 d-md-none d-block"> </div>
              <div className="col-md-2 fully-div">
                <div className="fully1">
                  <img src={fullyicon} alt="" />
                  <h3 className="fully-text-heading">DAO</h3>
                  <p>Decentralised Autonomous organisation</p>
                </div>
              </div>
              <div className="col-md-2 fully-div">
                <div className="fully1">
                  <img src={fullyicon} alt="" />
                  <h3 className="fully-text-heading">Staking</h3>
                  <p>Staking and earning rewards for the loyalty</p>
                </div>
              </div>
              <div className="col-md-2 fully-div">
                <div className="fully1">
                  <img src={fullyicon} alt="" />
                  <h3 className="fully-text-heading">Fan Rewards</h3>
                  <p>Meet & greet with players and more</p>
                </div>
              </div>
              <div className="col-md-1 d-md-none d-block"> </div>
            </div>
          </div>
        </section>

        <div className="container">
          <div className="row fast-button">
            <div className="col-md-3"></div>
            <div className="col-md-3">
              <div className="fast-btn">
                {/* <Downloadbutton/> */}

                <a href="https://qr.page/g/5j4P7cPInUZ" target="_blank">
                  <button className="fast-btn1">
                    <h3>download app</h3>
                    {/* <img className="img1" src={apple} /> */}
                    <img className="img2" src={android} />
                  </button>
                </a>
              </div>
            </div>
            <div className="col-md-3">
              <div className="trade">
                <button className="trade-btn " onClick={trade_pop}>
                  <h3>Trade Now</h3>
                </button>
              </div>
            </div>
            <div className="col-md-3"></div>
          </div>
        </div>

        {/* video section start here  */}

        {/* video section start here  */}
        <div className="row number1 number-speak d-flex mt-5">
              <div className="col-xl-3 pl-5 pl-xl-5 col-12 number">
                <h1>Our Numbers</h1>
                <h1> speaks</h1>
              </div>

              <div className="col-xl-3 col-6 py-4 speak text-xl-start text-center px-0">
                <h2>30,000</h2>
                <p>Wallets connected</p>
              </div>
              <div className="col-xl-3 col-6 py-4 text-xl-start text-center pl-sm-4 pl-2 speak speak-b">
                <h2>12457</h2>
                <p>Transactions Happened</p>
              </div>

              <div className="col-xl-3 pl-4 speak text-xl-start text-center">
                <h2>85</h2>
                <p>App Installs</p>
              </div>
            </div>
      
       <div style={{width:"100%"}} className="scrollvideo mb-3 mb-sm-0">
       <video
         autoPlay
         playsInline
         loop
         muted
        //  controls
       src='https://metalok-testbucket.s3.ap-south-1.amazonaws.com/roadmap.mp4' style={{width:'100%'}}>
        </video>
       </div>


        {/* <div ref={setHeightRef} id="set-height">
          <video ref={vidRef} id="v0">
            <source type="video/mp4" src="https://metalok-testbucket.s3.ap-south-1.amazonaws.com/sportsverseWebsiteVideo/roadmap_scrolling.mp4" />


            
          </video>
        </div> */}


        {/* video section end here */}
        {/* video section end here */}

        {/* <div className="parent-partner-section"></div> */}
        <Partner />
        <br></br>
        <Mediapartner />

        <section className="">
          <div className="container start-engine d-flex">
            <div className="row">
              <div className="col-md-7 d-flex align-items-center justify-content-center">
                <div className="start-eng py-4 mt-md-0 mt-5  pr-2">
                  <h1 className="mt-md-0 mt-5">Start Sports Trading Today</h1>
                  <p>
                    Engage with the Sportsverse platform and enjoy the benefits
                    of pioneering into sports trading and interactive match
                    experience
                  </p>

                  <a href="https://qr.page/g/5j4P7cPInUZ" target="_blank">
                    <button className="start-engine-btn">
                      <h2 className="btn-h">Download App</h2>
                      {/* <img src={apple} alt="apple" className="img1" /> */}
                      <img src={android} alt="apple" className="img2" />
                    </button>
                  </a>
                </div>
              </div>
              <div className="col-md-5 start-eng-1 d-flex align-items-center justify-content-center">
                <img src={iphone1} className="" />
              </div>
            </div>
          </div>
        </section>

        <br></br>
        <br></br>
        <CommunitySection />
        <br></br>
        <Faq />

        {/* <According/> */}
      </div>
      <br></br>

      <br></br>
      <br></br>

<br></br>
      <Footer />
    </div>
  );
};

export default HomePage;
