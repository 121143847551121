import { useRef } from "react";
import React, { useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import logob from "../icons/sports-black-nik.png";
import logo from "../icons/sports-white-nik.png";

import s_logo from "../icons/sportslogo.png";




import { NavLink } from "react-router-dom";
import "../stylesheet/header.css";
import HomePage from "./homePage";

// import RPC from '../web3RPC.ts';
import RPC from "../../web3RPC.ts";
import { Web3Auth } from "@web3auth/web3auth";
import { getPublic } from "@toruslabs/eccrypto";
// import { loginServer, postAvatarURL } from "../services/userService";
import { loginServer } from "../../services/userService";

import { useMediaPredicate } from "react-media-hook";
import { useDispatch,useSelector } from "react-redux";

import {userdata} from '../pages/redux/userslice'





function Header({
  logincolor,
  hdrop,
  hdropa,
  showDarkLogo,
  scrollColor,
  burgerColor 
}) {
  const clientId =
    "BK_TX48ntUieviViLOy8xwUhCirzTQI3uL7NwHsKkZk_-R7Zzpoxc2WNJDauT3OMRpolI7wlNRHUgT8SD0hjNDE";

  const [web3auth, setWeb3auth] = useState("");
  const [provider, setProvider] = useState("");
  const [idToken, setidToken] = useState("");
  const [pubKey, setpubKey] = useState("");
  const [userInfo, setUserInfo] = useState(null);

  const [showlogout, setShowlogout] = useState(false);

  function userinfo() {
    userInfo = {};
  }

  const usergetdata = useSelector((state)=>state.useralldata.userin)

  const userredux = useDispatch() 

  console.log("userInfo from REDUX", usergetdata)

  const login = async () => {
    if (!web3auth) {
      console.log("web3auth not initialized yet");
      return;
    }
    const web3authProvider = await web3auth.connect();
    setProvider(web3authProvider);
    console.log("web response: ", web3authProvider);
    
  };

  const getId = async () => {
    if (!web3auth) {
      console.log("web3auth not initialized yet");
      return;
    }
    const id = await web3auth.authenticateUser();
    setidToken(id.idToken);
  };

  const afterLogin = async () => {
    const res = await loginServer(idToken, pubKey);
    console.log(res);
    setUserInfo(res.user);
    setShowlogout(true);
    return true;
  };

  // const senData = async () =>{
  //   const apiURl = "https://backend.sportsverse.cricket/users/login/"
  //   const object ={
  //     publicKey:pubKey,
  //     userName:userInfo.user
  //   }
  // }

  const getPrivateKey = async () => {
    if (!provider) {
      console.log("provider not initialized yet");
      return;
    }
    const rpc = new RPC(provider);
    const privateKey = await rpc.getPrivateKey();
    return privateKey;
  };

  const getPubKey = async () => {
    if (!web3auth) {
      console.log("web3auth not initialized yet");
      return;
    }
    const app_scoped_privkey = await getPrivateKey();
    const app_pub_key = getPublic(
      Buffer.from(app_scoped_privkey.padStart(64, "0"), "hex")
    ).toString("hex");
    console.log(app_pub_key);
    setpubKey(app_pub_key);
  };

  const logout = async () => {
    if (!web3auth) {
      console.log("web3auth not initialized yet");
      return;
    }
    setProvider(null);
    setUserInfo(null);
    setShowlogout(false);
    await web3auth.logout();
  };

  useEffect(() => {
    const init2 = async () => {
      await getId();
      await getPubKey();
    };
    if (provider) init2();
  }, [provider]);

  useEffect(() => {
    const init2 = async () => {
      await getId();
      await getPubKey();
    };
    if (provider) init2();
  }, [provider]);

  useEffect(() => {
    const init3 = async () => {
      await afterLogin();
    };
    if (idToken && pubKey) {
      init3();
    }
  }, [idToken, pubKey]);

useEffect(()=>{
  userredux(userdata(userInfo))
},userInfo)
  useEffect(() => {
    const init = async () => {
      try {
        const web3auth = new Web3Auth({
          clientId: clientId,
          chainConfig: {
            chainNamespace: "eip155",
            chainId: "0x89", // hex of 80001, polygon testnet
            rpcTarget:
              "https://polygon-mainnet.g.alchemy.com/v2/Nk7m4OIjCz5bq189rdj83esGinAAL7MF",
          },
          authMode: "WALLET",
          uiConfig: {
            theme: "dark",
            loginMethodsOrder: ["facebook", "google"],
            appLogo:
              "https://metalok.io/wp-content/uploads/2022/06/image-1@2x.png", // Your App Logo Here
          },
          defaultLanguage: "en",
        });

        setWeb3auth(web3auth);

        await web3auth.initModal();
        if (web3auth.provider) {
          setProvider(web3auth.provider);
          console.log(provider);
        }
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, []);

  useEffect(() => {
    console.log(web3auth);
  }, [web3auth]);

  const [burger1, setburger1] = useState(false);

  function burger_click() {
    setburger1(!burger1);
  }

  const [colorChange, setColorchange] = useState(false);

  const changeNavbarColor = () => {
    if (window.scrollY >= 10) {
      setColorchange(true);
    } else if (window.scrollY <= 10) {
      setColorchange(false);
    }



  };
  


  window.addEventListener("scroll", changeNavbarColor);

  function Toptoscroll() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }


const moreThane1100 = useMediaPredicate("(max-width:1100px)")


  return (
    <>
      <div
        className={
          colorChange
            ? "header-container header-container-active"
            : "header-container"
        }
        style={{ backgroundColor: colorChange ? scrollColor : null }}
      >
        <div className="header-container-parent">
          {showDarkLogo ? (
           <NavLink onClick={Toptoscroll} to="/"><img src={logob} alt="" className="sports-logo none1-logo" />
           </NavLink> 
          ) : (
            <NavLink onClick={Toptoscroll} to="/">
            <img src={logo} alt="" className="sports-logo none1-logo" />
            </NavLink>
          )}
            <NavLink onClick={Toptoscroll} to="/">

            <img src={s_logo} alt="" className="sports-logo none2-logo" />
            </NavLink>
          <div
            className={burger1 ? "header-nav" : "header-nav header-nav-active"}


          style={{
              backgroundColor:moreThane1100  ? scrollColor : ""
             
            }}

          >
            <NavLink onClick={Toptoscroll} to="/" className="home-nav-top responsive-header-navlinks">
              Home
            </NavLink>








            
            {/* <NavLink to="/learnPage1">
              Learn
              </NavLink> */}

            <div className="dropdown responsive-header-navlinks">
              <button className="dropbtn" style={{ color: hdrop }}>
                Learn
              </button>
              <div className="dropdown-content">
                <NavLink style={{ color: hdropa }} onClick={Toptoscroll} to="/learnPage1" >
                  Learn  </NavLink>
                <NavLink style={{ color: hdropa }} onClick={Toptoscroll} to="/learnPage2">
                  Sprotsverse 101 </NavLink>
              </div>
            </div>

            <NavLink onClick={Toptoscroll} to="/FantokensPage" className="responsive-header-navlinks"> Fan Tokens </NavLink>

            {/* <NavLink to="/companyPage">Company</NavLink> */}

            <div className="dropdown responsive-header-navlinks">
              <button className="dropbtn" style={{ color: hdrop }}> Company </button>
              <div className="dropdown-content">
                <NavLink style={{ color: hdropa }} onClick={Toptoscroll} to="/companyPage">
                  About us </NavLink>
                <NavLink style={{ color: hdropa }} onClick={Toptoscroll} to="/careerPage">
                  Career</NavLink>
                <NavLink style={{ color: hdropa }} onClick={Toptoscroll} to="/supportPage">
                  Support</NavLink>
              </div>
            </div>

            <NavLink onClick={Toptoscroll} to="/roadmapPage" className="responsive-header-navlinks"> Roadmap </NavLink>

            <div className="btn-parent">

                <NavLink onClick={Toptoscroll} to="/withdrawMoney">
                <button className=" header-download" style={{ color: logincolor, borderColor: logincolor }} > Withdraw Money </button>
              </NavLink>

                {showlogout == false && (<button className="header-login responsive-header-navlinks2" onClick={login} style={{ backgroundColor: logincolor }}>
                    {" "}
                    SIGN UP{" "}
                  </button>
                )}
                {showlogout && ( <button className="header-login" style={{ backgroundColor: logincolor }} >{userInfo.name}</button>
                )}
            </div>
          </div>

        

          {/* {showlogout == false && <button className="header-login" onClick={login}>  SIGN UP </button>}
              {showlogout && <button className="header-login">{userInfo.name}</button>} */}

          <div className="burger-menu" onClick={burger_click}>
            {burger1 ? (
              <FaTimes className="fatb" style={{ fontSize: "30px",color:burgerColor }} />
            ) : (
              <FaBars className="fatb" style={{ fontSize: "30px",color:burgerColor }} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
