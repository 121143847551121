import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userin:''
 
}

const Userslice = createSlice({
    name:'data',
    initialState,
    reducers:{
        userdata:(state,action)=>{
            state.userin=action.payload
        }
    }
})

export const {userdata} = Userslice.actions
export default Userslice.reducer