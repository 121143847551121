import React, { useEffect, useState } from 'react';
import "../stylesheet/withdraw.css";

import wallet from "../icons/wallet-outline.png";
import walletfull from "../icons/wallet.png";
import key from "../icons/key-outline.png";
import keyfull from "../icons/key.png";
import arrow from "../icons/arrow-outline.png";
import arrowfull from "../icons/arrow.png";
import youtube from "../icons/youtube-1.png";

import Header from './header';

export const WithdrawMoney = () => {
    const [over, setOver] = useState(false);
    const [overone, setOverone] = useState(false);
    const [overtwo, setOvertwo] = useState(false);

    return (
        <div>



           <div className='withdraw-header'>
                <Header logincolor={"white"} scrollColor={"#ea9528"} burgerColor={"white"}/>
           </div>




            <div className='moneyback'>





                <section className='withdraw-how'>
                    <div className='container'>
                        <div className='row '>

                            <div className='col-md-6 withdraw'>
                                <h1 className='money-heading'>How To Withdraw Money?</h1>
                                <button className='withdraw-here-btn'>
                                    <a href='https://onramp.money/main/sell/?appId=114893' target="_blank">Withdraw Here</a>
                                </button>
                            </div>

                        </div>
                    </div>
                </section>

                <section>
                    <div className='container-fluid'>
                        <div className='row withdraw-overflow'>
                            <div className="col-md-4 step-card-1" onMouseOver={() => setOver(true)} onMouseOut={() => setOver(false)}>
                                <div className='step-card'>
                                    <h5>Step 1</h5>
                                    <h1>Create a metamask <br></br>wallet</h1>
                                    <p>
                                    Download the web extension of Metamask from the Metamask official site. Follow the instructions that follow to create your wallet
                                    </p>
                                    <a href="https://youtu.be/8mdi5aiQ7Mg" target="_blank">
                                        <p className='youtube-text'> Watch Video Tutorial <span><img src={youtube} /></span></p>
                                    </a>
                                    <img src={over ? walletfull : wallet} onMouseOver={e => (e.currentTarget.src = walletfull)} />
                                </div>
                            </div>

                            <div className="col-md-4 step-card-1" onMouseOver={() => setOverone(true)} onMouseOut={() => setOverone(false)}>
                                <div className='step-card'>
                                    <h5>Step 2</h5>
                                    <h1>Import private key to your metamask wallet</h1>
                                    <p>

                                    Import the private key generated for your digital wallet during the login with Sportsverse Platform into the Metamask wallet
                                    </p>

                                    <a href="https://youtu.be/OneXHJNwu0A" target="_black">
                                        <p className='youtube-text'> Watch Video Tutorial <span><img src={youtube} /> </span></p>
                                    </a>
                                    <img src={overone ? keyfull : key} onMouseOver={e => (e.currentTarget.src = keyfull)} />
                                </div>

                            </div>
                            <div className="col-md-4 step-card-1" onMouseOver={() => setOvertwo(true)} onMouseOut={() => setOvertwo(false)}>
                                <div className='step-card'>
                                    <h5>Step 3</h5>
                                    <h1>Click on ‘Withdraw Here’ button above</h1>
                                    <p>

                                    From the ‘Withdraw Here’ button on the page displayed, withdraw your rewards from trading, on the Sportsverse Platform
                                    </p>

                                    <a href="https://youtu.be/3lQuwhjImqU" target="_blank">
                                    <p className='youtube-text'> Watch Video Tutorial <span><img src={youtube} /> </span></p>
                                    </a>
                                    <img src={overtwo ? arrowfull : arrow} onMouseOver={e => (e.currentTarget.src = arrowfull)} />

                                </div>

                            </div>


                        </div>
                    </div>
                </section>

            </div>
        </div>
    )
}

export default WithdrawMoney;
